<template>
    <div id="app">
        <router-view></router-view>
        <b-navbar class="bottom-nav is-fixed-bottom nav-footer" >
            <b-navbar-nav class="nav-menu">
                <b-nav-item>
                    <router-link to="/dashboard" tag="li" :class="['text-center', 'nav-link', {active: url === 'dashboard'}]" exact>
                        <b-icon icon="house-door" font-scale="1" class="nav-icon"></b-icon>
                        <span class="nav-text">Home</span>
                    </router-link>
                </b-nav-item>
                <b-nav-item>
                    <router-link to="/order-list" tag="li" :class="['text-center', 'nav-link', {active: url === 'order-list'}]" exact>
                        <b-icon icon="file-earmark-plus" font-scale="1" class="nav-icon"></b-icon>
                        <span class="nav-text">Purchase</span>
                    </router-link>
                </b-nav-item>
                <b-nav-item>
                    <router-link to="/sale-list" tag="li" :class="['text-center', 'nav-link', {active: url === 'sale-list'}]" exact>
                        <b-icon icon="file-earmark-minus" font-scale="1" class="nav-icon"></b-icon>
                        <span class="nav-text">Sale</span>
                    </router-link>
                </b-nav-item>
                <b-nav-item>
                    <router-link to="/item-list" tag="li" :class="['text-center', 'nav-link', {active: url === 'item-list'}]" exact>
                        <b-icon icon="cart-plus" font-scale="1" class="nav-icon"></b-icon>
                        <span class="nav-text">Ordering</span>
                    </router-link>
                </b-nav-item>
                <b-nav-item >
                    <router-link to="/profile" tag="li" :class="['text-center', 'nav-link', {active: url === 'profile'}]" exact>
                        <b-icon icon="person" font-scale="1" class="nav-icon"></b-icon>
                        <span class="nav-text">Account</span>
                    </router-link>
                </b-nav-item>
            </b-navbar-nav>
        </b-navbar>
    </div>
</template>
<script>
    import Sidebar from '../components/partials/Sidebar'
    export default {
        name: 'admin',
        components: {
            Sidebar,
        },
        data() {
            return {
                url: ""
            }
        },
        watch: {
            '$route'(to, from) {
                this.url = to.name
            }
        },
        methods: {
            logout() {
                window.localStorage.setItem('token', null)
                this.$router.replace('/login')
            }
        },
        async mounted() {
            this.url = this.$route.name
        }
    }
</script>
<style lang="scss">
    @import "https://cdnjs.cloudflare.com/ajax/libs/bulma/0.7.2/css/bulma.min.css";
    // @import "../assets/main.css";
    // .nav-item {
    //     // width: 50px !important;
    //     // padding: 0px 54px 0px 20px;
    // }

   .cart {
        z-index: 999;
        position: absolute;
        background: red;
        width: 20px;
        height: 20px;
        color: #fff;
        bottom: 60%;
        right: 32%;
        font-size: 13px;
        font-weight: bolder;
        border-radius: 50%;
    }

    .navbar {
        z-index: 999 !important;
    }

    .nav-link {
        width: 100% !important;
        padding: 0px !important;
    }

    .nav-icon {
        width: 100% !important;
    }

    .nav-text {
        font-size: 15px !important;
    }

    .nav-footer {
        padding-left: 10%;
    }
</style>