import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/auth/Login'
import Admin from '../views/Admin'

Vue.use(VueRouter)

const routes = [{
		path: '/login',
		name: 'login',
		component: Login
	},
	{
		path: '/admin',
		name: 'admin',
		component: Admin,
		children: [{
			path: '/dashboard',
			name: 'dashboard',
			component: () => import('../views/Dashboard'),
		},
		{
			path: '/return-list',
			name: 'return-list',
			component: () => import('../views/return/ReturnList'),
		},
		{
			path: '/item-list',
			name: 'item-list',
			component: () => import('../views/addtocart/ItemList'),
		},
		{
			path: '/order-create',
			name: 'order-create',
			component: () => import('../views/addtocart/OrderCreate'),
		},
		{
			path: '/order-list',
			name: 'order-list',
			component: () => import('../views/order/OrderList'),
		},
		{
			path: '/sale-list',
			name: 'sale-list',
			component: () => import('../views/sale/SaleList'),
		},
		{
			path: '/profile',
			name: 'profile',
			component: () => import('../views/auth/Profile'),
		}
		]
	},

	{
		path: '/*',
		redirect: '/dashboard'
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})


export default router